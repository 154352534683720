<template>
  <div class="approvals-page">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Approvals',
};
</script>
